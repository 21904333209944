import React from "react"
import styled from "styled-components"

const NotFound = () => {
  return (
    <StyledNotFound>
      <h1>404</h1>
      <h3>A keresett oldal nem található!</h3>
    </StyledNotFound>
  )
}

export default NotFound

const StyledNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #393e46;

  h1 {
    font-size: 10rem;
    font-weight: 500;
  }

  h3 {
    font-size: 3rem;
    font-weight: 300;
    text-align: center;
  }
`
